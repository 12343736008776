import React, { useEffect, useState } from "react";
import Aos from "aos";
import axios from "axios";
import { Container, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

import Carousel from "../component/Slider";
import sliderImg3 from "../assets/images/carousel5.webp";
import sliderImg4 from "../assets/images/carousel4.webp";
import productTextImg from "../assets/images/productTextImg.webp";
import Loader from "./Loader";
import Svg from "../component/Svg";
import { Col, Row } from "react-bootstrap";

export default function NewProduct() {
	const [title, setTitle] = useState("Product - The BroadCast Store");
	const [description, setDescription] = useState(
		"Broadcast Store is a professional audiovisual equipment company providing services like consignment, system integration, and media migration etc.",
	);

	const imgItem = [
		{ id: 3, src: sliderImg3 },
		{ id: 4, src: sliderImg4 },
	];

	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [allProducts, setAllProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState("all");
	const [selectedCategory, setSelectedCategory] = useState("all");
	const [selectedResolution, setSelectedResolution] = useState("all");
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;

	const [showAllCategories, setShowAllCategories] = useState(false);
	const [showAllBrands, setShowAllBrands] = useState(false);

	useEffect(() => {
		document.title = title;
		document
			.getElementById("meta-description")
			.setAttribute("content", description);
		Aos.init({ duration: 2000 });
		window.scrollTo(0, 0);
	}, [title, description]);

	// Fetch all products (new products only)
	useEffect(() => {
		setLoading(true);
		axios
			.get("https://thebroadcaststore.co/admins/api/all-products")
			.then((response) => {
				const filteredProducts = response.data.filter(
					(product) => product.new_product === 1,
				);
				setAllProducts(filteredProducts);
				setFilteredProducts(filteredProducts);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching products:", error);
				setLoading(false);
			});
	}, []);

	// Fetch brands
	useEffect(() => {
		setLoading(true);
		axios
			.get("https://thebroadcaststore.co/admins/api/all-brands")
			.then((response) => {
				setBrands(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching brands:", error);
				setLoading(false);
			});
	}, []);

	// Fetch categories
	useEffect(() => {
		setLoading(true);
		axios
			.get("https://thebroadcaststore.co/admins/api/all-category")
			.then((response) => {
				const filteredCategories = response.data.filter((category) =>
					category.title.toLowerCase().includes("new"),
				);
				setCategories(filteredCategories);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching categories:", error);
				setLoading(false);
			});
	}, []);

	// Handle filtering logic
	// Handle filtering logic
	useEffect(() => {
		let filtered = allProducts;

		// Apply brand filtering
		if (selectedBrand !== "all") {
			filtered = filtered.filter(
				(product) => product.brand_id === selectedBrand,
			);
		}

		// Apply category filtering
		if (selectedCategory !== "all") {
			filtered = filtered.filter(
				(product) => product.category_id === selectedCategory,
			);
		}

		// Apply resolution filtering
		if (selectedResolution !== "all") {
			filtered = filtered.filter(
				(product) =>
					product.resolution &&
					product.resolution.toLowerCase().includes(selectedResolution),
			);
		}

		setFilteredProducts(filtered);
	}, [selectedBrand, selectedCategory, selectedResolution, allProducts]);

	// Calculate the products to show for the current page
	const indexOfLastProduct = currentPage * itemsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
	const currentProducts = filteredProducts.slice(
		indexOfFirstProduct,
		indexOfLastProduct,
	);

	// Handle pagination change
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// Pagination buttons
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
		pageNumbers.push(i);
	}

	// Category filter change handler
	const handleCategoryChange = (categoryId) => {
		setSelectedCategory(categoryId.toString());
		setCurrentPage(1); // Reset to page 1 when changing category
	};

	return (
		<>
			<div className="carousel-container1">
				<Carousel
					slides={imgItem}
					autoSlide={true}
					autoSlideInterval={5000}
				/>
				<div className="contact-bannerText product-slider-text">
					<img
						src={productTextImg}
						alt="product-bannerText-Img"
						className="product-bannerText-Img"
					/>
					<h1
						className="contact-heading aos-init aos-animate product-slider-heading"
						data-aos="fade-up">
						New Products
					</h1>
				</div>
			</div>
			{loading ? (
				<Loader />
			) : (
				<Grid
					className="ft-products brd-line"
					id="all-pro">
					<Container>
						<div className="ft-pro-head">
							<Row className="products-heading">
								<Col>
									<span
										data-aos="fade-right"
										data-aos-offset="300"
										data-aos-easing="ease-in-sine"
										className="webkit-text products-bgtext">
										PRODUCTS
									</span>
									<div data-aos="fade-up">
										<h2 className="heading">Featured Products</h2>
									</div>
								</Col>
							</Row>
						</div>
						<div className="filter-product">
							<div className="filter-sidebar">
								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Categories</h3>
									<div className="check-box">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_categories"
												name="category"
												checked={selectedCategory === "all"}
												onChange={() => setSelectedCategory("all")}
											/>
											<label
												htmlFor="all_categories"
												className="check-box-para">
												All Categories
											</label>
										</div>
										{categories
											.slice(0, showAllCategories ? categories.length : 5)
											.map((category) => (
												<>
													<div
														className="brands-check-box"
														key={category.id}>
														<input
															type="radio"
															id={`category_${category.id}`}
															name="category"
															checked={
																selectedCategory === category.id.toString()
															}
															onChange={() => handleCategoryChange(category.id)}
														/>
														<label
															htmlFor={`category_${category.id}`}
															className="check-box-para">
															{category.title.slice(3)}
														</label>
													</div>
												</>
											))}
										<Button
											onClick={() => setShowAllCategories(!showAllCategories)}
											className="show-more-btn">
											{showAllCategories ? "Show Less" : "Show More"}
										</Button>
									</div>
								</div>
								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Brands</h3>
									<div className="check-box">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_brands"
												name="brand"
												checked={selectedBrand === "all"}
												onChange={() => setSelectedBrand("all")}
											/>
											<label
												htmlFor="all_brands"
												className="check-box-para">
												All Brands
											</label>
										</div>
										{brands
											.slice(0, showAllBrands ? brands.length : 5)
											.map((brand) => (
												<div
													className="brands-check-box"
													key={brand.id}>
													<input
														type="radio"
														id={`brand_${brand.id}`}
														name="brand"
														checked={selectedBrand === brand.id}
														onChange={() => setSelectedBrand(brand.id)}
													/>
													<label
														htmlFor={`brand_${brand.id}`}
														className="check-box-para">
														{brand.title}
													</label>
												</div>
											))}
										<Button
											onClick={() => setShowAllBrands(!showAllBrands)}
											className="show-more-btn">
											{showAllBrands ? "Show Less" : "Show More"}
										</Button>
									</div>
								</div>
								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Resolution</h3>
									<div className="check-box">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_resolution"
												name="resolution"
												checked={selectedResolution === "all"}
												onChange={() => setSelectedResolution("all")}
											/>
											<label
												htmlFor="all_resolution"
												className="check-box-para">
												All Resolutions
											</label>
										</div>
										<div className="brands-check-box">
											<input
												type="radio"
												id="resolution_8k"
												name="resolution"
												checked={selectedResolution === "8k"}
												onChange={() => setSelectedResolution("8k")}
											/>
											<label
												htmlFor="resolution_8k"
												className="check-box-para">
												8k
											</label>
										</div>
										<div className="brands-check-box">
											<input
												type="radio"
												id="resolution_6k"
												name="resolution"
												checked={selectedResolution === "6k"}
												onChange={() => setSelectedResolution("6k")}
											/>
											<label
												htmlFor="resolution_6k"
												className="check-box-para">
												6k
											</label>
										</div>
										<div className="brands-check-box">
											<input
												type="radio"
												id="resolution_4k"
												name="resolution"
												checked={selectedResolution === "4k"}
												onChange={() => setSelectedResolution("4k")}
											/>
											<label
												htmlFor="resolution_4k"
												className="check-box-para">
												4K
											</label>
										</div>
										<div className="brands-check-box">
											<input
												type="radio"
												id="resolution_1080"
												name="resolution"
												checked={selectedResolution === "1080"}
												onChange={() => setSelectedResolution("1080")}
											/>
											<label
												htmlFor="resolution_1080"
												className="check-box-para">
												1080p
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="product-page">
								<div className="products-container">
									{currentProducts.length > 0 ? (
										<>
											{currentProducts.map((product) => (
												<div
													className="ftrd-slide"
													key={product.id}>
													<div className="ftrd-pro">
														<div className="product">
															<h4 className="title">{product.title}</h4>
															<div className="pro-image">
																<img
																	src={`https://thebroadcaststore.co/admins/public/${product.img_path}`}
																	alt={product.title}
																/>
															</div>
															<p className="short-des">{product.short_desc}</p>
															<div className="price-btn">
																<p className="price">Call for Price</p>
																<Button className="lrn-more cart">
																	<Link to={`/product-detail/${product.slug}`}>
																		Learn More
																	</Link>
																</Button>
															</div>
														</div>
													</div>
												</div>
											))}
										</>
									) : (
										<div className="reset-btn-container">
											<h3 className="reset-title">No products found!</h3>
											<div className="reset-filters">
												<Button
													variant="contained"
													className="reset-button"
													onClick={() => {
														setSelectedBrand("all");
														setSelectedCategory("all");
														setSelectedResolution("all");
													}}>
													Reset Filters
												</Button>
											</div>
										</div>
									)}
								</div>
								<Svg />
								<div className="pagination">
									{pageNumbers.map((number) => (
										<button
											key={number}
											onClick={() => paginate(number)}
											className={number === currentPage ? "active" : ""}>
											{number}
										</button>
									))}
								</div>
							</div>
						</div>
					</Container>
				</Grid>
			)}
		</>
	);
}

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MobileNav from "../../Pages/MobileNavbar";
import { NavLink, useNavigate } from "react-router-dom";
import thebroadcastlogo from "../../assets/images/thebroadcastlogo.webp";

export default function Navbarheader() {
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		navigate(`/search?q=${searchQuery}`);
	};

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	return (
		<>
			<div className="navbar-container">
				<nav className="nav">
					<div className="logo">
						<NavLink to="/">
							<img
								src={thebroadcastlogo}
								className="d-inline-block align-top"
								alt="Logo"
							/>
						</NavLink>
					</div>
					<div className="navbars">
						<div className="left-col">
							<NavLink
								className="link"
								to="/"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								Home
							</NavLink>
							<NavLink
								className="link"
								to="/about"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								About Us
							</NavLink>
							<NavLink
								className="link"
								to="/product"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								Products
							</NavLink>
							<NavLink
								className="link"
								to="/new-products"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								New Products
							</NavLink>
							<NavLink
								className="link"
								to="/contact"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								Contact
							</NavLink>
						</div>
					</div>
					<div className="topbar">
						<div className="search__main">
							<Row>
								<Col
									className="tp-width"
									xs={8}>
									<div className="header__form search-bar">
										<Form
											className="d-flex"
											onSubmit={handleSearchSubmit}>
											<Form.Control
												type="search"
												placeholder="Search"
												className="me-2"
												aria-label="Search"
												value={searchQuery}
												onChange={handleSearchInputChange}
											/>
											<Button
												className="product-link theme-btn"
												type="submit">
												Search
											</Button>
										</Form>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</nav>
			</div>
			<MobileNav />
		</>
	);
}

import React, { useEffect, useState } from "react";
import Aos from "aos";
import axios from "axios";
import { Container, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

import Carousel from "../component/Slider";
import sliderImg1 from "../assets/images/carousel1.webp";
import sliderImg2 from "../assets/images/carousel2.webp";
import sliderImg3 from "../assets/images/carousel5.webp";
import sliderImg4 from "../assets/images/carousel4.webp";
import productTextImg from "../assets/images/productTextImg.webp";
import Loader from "./Loader";
import Svg from "../component/Svg";
import { Col, Row } from "react-bootstrap";

export default function AllNewProduct() {
	const [title, setTitle] = useState("Product - The BroadCast Store");
	const [description, setDescription] = useState(
		"Broadcast Store is a professional audiovisual equipment company providing services like consignment, system integration, and media migration etc.",
	);

	const imgItem = [
		{ id: 1, src: sliderImg1 },
		{ id: 2, src: sliderImg2 },
		{ id: 3, src: sliderImg3 },
		{ id: 4, src: sliderImg4 },
	];

	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [allProducts, setAllProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState("all");
	const [selectedCategory, setSelectedCategory] = useState("all");
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;

	useEffect(() => {
		document.title = title;
		document
			.getElementById("meta-description")
			.setAttribute("content", description);
		Aos.init({ duration: 2000 });
		window.scrollTo(0, 0);
	}, [title, description]);

	// Fetch all products (new products only)
	useEffect(() => {
		setLoading(true);
		axios
			.get("https://thebroadcaststore.co/admins/api/all-products")
			.then((response) => {
				const filteredProducts = response.data.filter(
					(product) => product.new_product === 1,
				);
				setAllProducts(filteredProducts);
				setFilteredProducts(filteredProducts);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching products:", error);
				setLoading(false);
			});
	}, []);

	// Fetch brands
	useEffect(() => {
		setLoading(true);
		axios
			.get("https://thebroadcaststore.co/admins/api/all-brands")
			.then((response) => {
				setBrands(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching brands:", error);
				setLoading(false);
			});
	}, []);

	// Fetch categories
	useEffect(() => {
		setLoading(true);
		axios
			.get("https://thebroadcaststore.co/admins/api/all-category")
			.then((response) => {
				const filteredCategories = response.data.filter((category) =>
					category.title.toLowerCase().includes("new"),
				);
				setCategories(filteredCategories);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching categories:", error);
				setLoading(false);
			});
	}, []);

	// Handle filtering logic
	useEffect(() => {
		// Log initial values for debugging
		console.log("Filtering products...");
		console.log("Selected Brand:", selectedBrand);
		console.log("Selected Category:", selectedCategory);

		let filtered = allProducts;

		// Apply brand filtering
		if (selectedBrand !== "all") {
			filtered = filtered.filter((product) => {
				console.log("Checking brand_id:", product.brand_id); // Debug
				return product.brand_id && product.brand_id === selectedBrand;
			});
		}

		// Apply category filtering
		if (selectedCategory !== "all") {
			filtered = filtered.filter((product) => {
				console.log("Checking category_id:", product.category_id); // Debug
				return Number(product.category_id) === Number(selectedCategory);
			});
		}

		// Log filtered products for debugging
		console.log("Filtered Products:", filtered);

		setFilteredProducts(filtered);
	}, [selectedBrand, selectedCategory, allProducts]);

	// Calculate the products to show for the current page
	const indexOfLastProduct = currentPage * itemsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
	const currentProducts = filteredProducts.slice(
		indexOfFirstProduct,
		indexOfLastProduct,
	);

	// Handle pagination change
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// Pagination buttons
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<>
			<div className="carousel-container1">
				<Carousel
					slides={imgItem}
					autoSlide={true}
					autoSlideInterval={5000}
				/>
				<div className="contact-bannerText product-slider-text">
					<img
						src={productTextImg}
						alt="product-bannerText-Img"
						className="product-bannerText-Img"
					/>
					<h1
						className="contact-heading aos-init aos-animate product-slider-heading"
						data-aos="fade-up">
						All Products
					</h1>
				</div>
			</div>
			{loading ? (
				<Loader />
			) : (
				<Grid
					className="ft-products brd-line"
					id="all-pro">
					<Container>
						<div className="ft-pro-head">
							<Row className="products-heading">
								<Col>
									<span
										data-aos="fade-right"
										data-aos-offset="300"
										data-aos-easing="ease-in-sine"
										className="webkit-text products-bgtext">
										PRODUCTS
									</span>
									<div data-aos="fade-up">
										<h2 className="heading">Featured Products</h2>
									</div>
								</Col>
							</Row>
						</div>
						<div className="filter-product">
							<div className="filter-sidebar">
								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Categories</h3>
									<div className="check-box">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_categories"
												name="category"
												checked={selectedCategory === "all"}
												onChange={() => setSelectedCategory("all")}
											/>
											<label
												htmlFor="all_categories"
												className="check-box-para">
												All Categories
											</label>
										</div>
										{categories.map((category) => (
											<div
												className="brands-check-box"
												key={category.id}>
												<input
													type="radio"
													id={`category_${category.id}`}
													name="category"
													checked={selectedCategory === category.id}
													onChange={() => setSelectedCategory(category.id)}
												/>
												<label
													htmlFor={`category_${category.id}`}
													className="check-box-para">
													{category.title.slice(3)}
												</label>
											</div>
										))}
									</div>
								</div>
								<div className="filter-sidebar2">
									<h3 className="filter-sidebar2-title">Brands</h3>
									<div className="check-box">
										<div className="brands-check-box">
											<input
												type="radio"
												id="all_brands"
												name="brand"
												checked={selectedBrand === "all"}
												onChange={() => setSelectedBrand("all")}
											/>
											<label
												htmlFor="all_brands"
												className="check-box-para">
												All Brands
											</label>
										</div>
										{brands.map((brand) => (
											<div
												className="brands-check-box"
												key={brand.id}>
												<input
													type="radio"
													id={`brand_${brand.id}`}
													name="brand"
													checked={selectedBrand === brand.id}
													onChange={() => setSelectedBrand(brand.id)}
												/>
												<label
													htmlFor={`brand_${brand.id}`}
													className="check-box-para">
													{brand.title}
												</label>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="product-page">
								<div className="products-container">
									{currentProducts.length > 0 ? (
										<>
											{currentProducts.map((product) => (
												<div
													className="ftrd-slide"
													key={product.id}>
													<div className="ftrd-pro">
														<div className="product">
															<h4 className="title">{product.title}</h4>
															<div className="pro-image">
																<img
																	src={`https://thebroadcaststore.co/admins/public/${product.img_path}`}
																	alt={product.title}
																/>
															</div>
															<p className="short-des">{product.short_desc}</p>
															<div className="price-btn">
																<p className="price">Call for Price</p>
																<Button className="lrn-more cart">
																	<Link to={`/product-detail/${product.slug}`}>
																		Learn More
																	</Link>
																</Button>
															</div>
														</div>
													</div>
												</div>
											))}
										</>
									) : (
										<p className="short-des">No products found!</p>
									)}
								</div>
								<Svg />
								<div className="pagination">
									{pageNumbers.map((number) => (
										<button
											key={number}
											onClick={() => paginate(number)}
											className={number === currentPage ? "active" : ""}>
											{number}
										</button>
									))}
								</div>
							</div>
						</div>
					</Container>
				</Grid>
			)}
		</>
	);
}

import { Link, NavLink, useNavigate } from "react-router-dom";
import MobileNav from "../../Pages/MobileNavbar";
import homeLogo from "../../assets/images/homeLogo.png";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DropDown from "../DropDown";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export default function HomeNavbarheader() {
	const [productCat, setProductCat] = useState([]);
	const [topBrands, setTopBrands] = useState([]);

	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("https://thebroadcaststore.co/admins/api/all-category")
			.then((response) => {
				setProductCat(response.data);
			})
			.catch((error) => {
				console.error("Error fetching category:", error);
			});
	}, []);

	useEffect(() => {
		axios
			.get("https://thebroadcaststore.co/admins/api/all-top-products")
			.then((response) => {
				setTopBrands(response.data);
			})
			.catch((error) => {
				console.error("Error fetching category:", error);
			});
	}, []);

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		navigate(`/search?q=${searchQuery}`);
	};

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const handleTabSelect = () => {
		window.history.pushState(null, null, `/product`);
	};

	const tabsBoxRef = useRef(null);
	const [products, setProducts] = useState([]);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [categorySelected, setCategorySelected] = useState(false);
	const [categoryName, setCategoryName] = useState("All Products");

	const handleBrandSelect = (e) => {
		const selectedBrand = e.target.value;
		if (selectedBrand) {
			navigate(`/brand/${selectedBrand.toLowerCase()}`);
		}
	};
	const handleTabClick = (category) => {
		axios
			.get(
				`https://thebroadcaststore.co/admins/api/products/category/${category.id}`,
			)
			.then((response) => {
				setProducts(response.data);
				setSelectedProduct(null);
				setSelectedCategory(category);
				setCategoryName(category.title); // Set category name dynamically
			})
			.catch((error) => {
				console.error("Error fetching products:", error);
			});
		setCategorySelected(!categorySelected);
	};

	useEffect(() => {
		axios
			.get("https://thebroadcaststore.co/admins/api/all-category")
			.then((response) => {
				setProductCat(response.data);
			})
			.catch((error) => {
				console.error("Error fetching category:", error);
			});
	}, []);

	return (
		<>
			<div className="navbar-container">
				<nav className="nav">
					<div className="logo">
						<NavLink to="/">
							<img
								src={homeLogo}
								className="d-inline-block align-top"
								alt="Logo"
							/>
						</NavLink>
					</div>
					<div className="navbars">
						<div className="left-col">
							<NavLink
								className="link home-link"
								to="/"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								Home
							</NavLink>
							<NavLink
								className="link home-link"
								to="/about"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								About Us
							</NavLink>
							<NavLink
								className="link home-link"
								to="/product"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								Products
							</NavLink>
							<NavLink
								className="link home-link"
								to="/new-products"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								New Products
							</NavLink>
							<NavLink
								className="link home-link"
								to="/contact"
								activeClassName="active"
								style={({ isActive }) => ({
									fontWeight: isActive ? "bold" : "normal",
								})}>
								Contact
							</NavLink>
						</div>
					</div>
					<div className="topbar">
						<div className="search__main">
							<Row>
								<Col
									className="tp-width"
									xs={8}>
									<div className="header__form search-bar home_header__form">
										<Form
											className="d-flex"
											onSubmit={handleSearchSubmit}>
											<Form.Control
												type="search"
												placeholder="Search"
												className="me-2"
												aria-label="Search"
												value={searchQuery}
												onChange={handleSearchInputChange}
											/>
											<Button
												className="product-link theme-btn"
												type="submit">
												Search
											</Button>
										</Form>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</nav>
				{/* <div className="wrapper navbar-wrapper">
					<div
						className="icon"
						onClick={() => handleIconClick("left")}>
						<MdKeyboardArrowLeft
							size={10}
							className="i"
						/>
					</div>
					{
						<ul
							className="tabs-box navbar-tabs-box"
							ref={tabsBoxRef}>
							{productCat.map((category, index) => (
								<Link
									className={`navbar-tab ${
										selectedCategory && selectedCategory.id === category.id
											? "active"
											: ""
									}`}
									key={index}
									onClick={() => handleTabClick(category)}>
									<select
										style={{
											padding: "5px",
											borderRadius: "5px",
											border: "none",
											fontSize: "16px",
											textTransform: "capitalize",
											fontWeight: "normal",
											cursor: "pointer",
											backgroundColor: "#f2f2f2",
											color: "#333",
											boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
											transition: "background-color",
											transitionDuration: "0.3s",
											transitionTimingFunction: "ease-in-out",
											outline: "none",
											transitionProperty: "background-color",
										}}
										value={category.title}
										onChange={handleBrandSelect}>
										<option>{category.title}</option>
										<option value={category.title}>{category.title}</option>
									</select>
								</Link>
							))}
						</ul>
					}
					<div
						className="icon"
						onClick={() => handleIconClick("right")}>
						<MdKeyboardArrowRight
							size={10}
							className="i"
						/>
					</div>
				</div> */}
			</div>
			<MobileNav />
		</>
	);
}
